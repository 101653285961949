import router from '../router/index'
import axios from 'axios'
import cookie from 'js-cookie'



const server = axios.create({
    timeout: 7600,
    timeoutErrorMessage: '请求超时',
})


server.interceptors.request.use(async (config) => {

    config.headers['x-csrf-token'] = document.cookie.slice(10)

    if (cookie.get('token')) {
        config.headers.Authorization = cookie.get('token')
    }

    return config

}, (error) => {

    return Promise.reject(error)

})

server.interceptors.response.use((config) => {

    return config.data

}, (error) => {
        switch (error.response.status) {
            case 404:
                cookie.set('code', error.response.status)
                router.push('/error')
                break
        }


    return Promise.reject(error)
})

export default server